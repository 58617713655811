import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~3],
		"/auth": [~5],
		"/auth/forgot": [6],
		"/auth/logout": [~7],
		"/auth/password-reset-link": [8],
		"/ezy": [9,[2]],
		"/ezy/account": [13,[2]],
		"/ezy/booking": [~14,[2]],
		"/ezy/booking/payment": [~15,[2]],
		"/ezy/booking/payment/success": [~16,[2]],
		"/ezy/find-space": [17,[2]],
		"/ezy/find-space/timeslot": [18,[2]],
		"/ezy/insurance": [19,[2]],
		"/ezy/(earn)/lets-speak": [10,[2]],
		"/ezy/manual": [20,[2]],
		"/ezy/my-bookings": [~21,[2]],
		"/ezy/my-bookings/edit-booking": [22,[2]],
		"/ezy/my-bookings/payments": [23,[2]],
		"/ezy/my-rooms/auto/new2": [~25,[2]],
		"/ezy/my-rooms/auto/new": [~24,[2]],
		"/ezy/my-rooms/edit-room": [~26,[2]],
		"/ezy/my-rooms/key": [~27,[2]],
		"/ezy/my-rooms/logs": [~28,[2]],
		"/ezy/my-rooms/schedule": [~29,[2]],
		"/ezy/my-rooms/schedule/edit-schedule": [~30,[2]],
		"/ezy/(earn)/resell": [11,[2]],
		"/ezy/(earn)/share": [12,[2]],
		"/ezy/shop": [~31,[2]],
		"/ezy/shop/[slug]": [~32,[2]],
		"/ezy/support": [33,[2]],
		"/ezy/test/mqtt": [34,[2]],
		"/greete": [35],
		"/greete/kingitus": [36],
		"/listen": [37],
		"/open": [~38],
		"/smart-solutions": [39],
		"/[slug]": [~4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';